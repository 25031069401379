import React from 'react';

const IconLogo = () => (
  <svg
    width="453"
    height="332"
    viewBox="0 0 453 332"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M444.035 323L294.446 10H163.084L307.645 323H444.035Z"
      fill="white"
      stroke="#64FFDA"
      stroke-width="18"
      stroke-linecap="round"
      stroke-linejoin="bevel"
    />
    <path
      d="M164.066 12.0867L8.85522 322.304H230.625"
      stroke="#64FFDA"
      stroke-width="18"
      stroke-linecap="square"
      stroke-linejoin="bevel"
    />
    <path d="M158.697 156.858L216.533 265.147H97.8601L158.697 156.858Z" fill="#64FFDA" />
    <ellipse cx="225.433" cy="43.6107" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="237.301" cy="79.3221" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="258.068" cy="115.034" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="274.386" cy="150.745" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="290.704" cy="186.456" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="307.021" cy="222.168" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="323.338" cy="257.879" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="339.656" cy="293.591" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="269.936" cy="43.6107" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="281.803" cy="79.3221" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="302.571" cy="115.034" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="318.889" cy="150.745" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="335.206" cy="186.456" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="351.524" cy="222.168" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="367.841" cy="257.879" rx="10.3839" ry="7.35235" fill="#64FFDA" />
    <ellipse cx="384.159" cy="293.591" rx="10.3839" ry="7.35235" fill="#64FFDA" />
  </svg>
);

export default IconLogo;
